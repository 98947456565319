@tailwind base;
@tailwind components;
@tailwind utilities;
#WelcomePage {
  background: radial-gradient(56.19% 56.19% at 50% 100%, #1a1a1a 0%, #21211e 100%), radial-gradient(141.62% 141.42% at 100% 100%, #1a1a1a 0%, #42413e 100%), #fff; }

#WelcomeText {
  background: linear-gradient(180deg, #f3f2f2 0%, #696763 55.9%, #fafafa 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

#startupBox {
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: radial-gradient(161.2% 129.6% at -9% 6.86%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.08) 15.59%, rgba(255, 255, 255, 0.04) 28.65%, rgba(255, 255, 255, 0.1) 43.8%, rgba(255, 255, 255, 0.03) 59.27%, rgba(255, 255, 255, 0.05) 69.68%, rgba(255, 255, 255, 0.02) 83.55%, rgba(255, 255, 255, 0.04) 100%); }

.heading-text {
  background: linear-gradient(180deg, #ff7e6e 0%, #ffbc00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.startupBg {
  background: radial-gradient(56.19% 56.19% at 50% 100%, #1a1a1a 0%, #21211e 100%), radial-gradient(205.69% 129.15% at 0% 90.49%, #1a1a1a 0%, #2b2b29 25.14%, #212121 40.58%, #1f1e1c 60.31%, #2b2b2b 81.91%, #242424 100%); }

.text-gradient {
  background: linear-gradient(180deg, #f3f2f2 0%, #696763 55.9%, #fafafa 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.logo-text {
  background: linear-gradient(180deg, #f3f2f2 0%, #696763 55.9%, #fafafa 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

input[type="checkbox"]:checked {
  background-color: #1a1a1a !important;
  color: #ffffff !important; }

* {
  -webkit-tap-highlight-color: transparent; }

.service-box {
  height: 250px;
  transition: all 0.5s ease-in-out; }

.service-box-expanded {
  height: 350px;
  transition: all 0.5s ease-in-out; }

.service-box1 {
  height: 0px;
  transition: all 0.5s ease-in-out; }

.service-box1-expanded {
  height: 100px;
  transition: all 0.5s ease-in-out; }

.show-issues {
  opacity: 1;
  -webkit-animation: reveal 1.5s ease-in-out;
  -moz-animation: reveal 1.5s ease-in-out;
  -ms-animation: reveal 1.5s ease-in-out;
  -o-animation: reveal 1.5s ease-in-out;
  animation: reveal 1.5s ease-in-out; }

.hide-issues {
  opacity: 0;
  -webkit-animation: hide 4s ease-in-out;
  -moz-animation: hide 4s ease-in-out;
  -ms-animation: hide 4s ease-in-out;
  -o-animation: hide 4s ease-in-out;
  animation: hide 4s ease-in-out; }

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  50% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-moz-keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  50% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-ms-keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  50% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-o-keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  50% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  50% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }

@-moz-keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }

@-ms-keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }

@-o-keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }

@keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-20px); } }
